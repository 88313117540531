<template>
  <div>
    <div class="lee-chat">
      <div class="lee-chat-main">
        <div class="lee-chat-left">
          <div class="lee-chat-userInfo">
            <div class="lee-chat-info">
              <img src="@/assets/img/logo.jpg" alt="">
              <span>{{ userInfo.username }}</span>
            </div>
            <div class="lee-chat-userInfoBtn">
              <el-popconfirm title="请确认是否退出?" @confirm="exitChat">
                <el-button slot="reference" type="primary" plain size="mini">退出</el-button>
              </el-popconfirm>
            </div>
          </div>
          <div class="lee-chat-userList">
            <el-skeleton v-if="wxUserListLoading" :rows="10" animated/>
            <div v-else>
              <div class="lee-chat-userList-item" :class="item.weId == chatingUserId ? 'activeUser' : ''"
                   v-for="(item, index) in wxUserList" :key="index" @click="selectChatUser(item.weId,index)">
                <img v-if="!item.headImg || (item.headImg && item.headImg.indexOf('http') == '-1')"
                     src="@/assets/img/logo.jpg" alt="">
                <img v-else="item.headImg" :src="item.headImg" alt="">
                <span>{{ item.weUserName || "微信用户-" + index }}</span>
                <div class="userList-news" v-show="item.isNewMsg">NEW</div>
              </div>
            </div>
          </div>
        </div>
        <div class="lee-chat-right">
          <div class="lee-chat-top">
            <div class="lee-chat-company">
              <img src="@/assets/img/logo.jpg" alt="">
              <span>唯达宁在线客服系统</span>
            </div>
            <div class="lee-chat-top-menuBtn">
              <el-button type="danger" plain v-if="SocketTaskOnline" @click="leaveWork">暂离</el-button>
              <el-button type="primary" v-else :loading="SocketTaskOnlineLoading"
                         @click="initWebSocket">工作
              </el-button>
            </div>
          </div>
          <div class="lee-chat-center">
            <div class="lee-chat-content">
              <div class="lee-chat-list">
                <el-empty v-if="!chatingUserId" description="请选择用户"></el-empty>
                <el-skeleton v-else-if="chatingUserId && chatHisListLoging" :rows="10" animated/>
                <div v-else-if="chatingUserId && !chatHisListLoging" class="lee-chat-list-ul">
                  <div class="lee-chat-list-li" v-for="(item, index) in chatHisList" :key="index"
                       v-if="['1', '2', '3', '999']">
                    <!-- // 角色类型 1、用户 2、客服 3、智能文本 999、动作提示 -->
                    <div class="left" v-if="item.roleType == '1' || item.roleType == '4'">
                      <img v-if="!item.headImg || (item.headImg && item.headImg.indexOf('http') == '-1')"
                           src="@/assets/img/logo.jpg"
                           alt="">
                      <img v-else="item.headImg" :src="item.headImg" alt="">
                      <div class="chatContent">
                        <!-- 普通文字  // 消息类型 1、文字 2、图片 3、语音-->
                        <div v-html="item.content" v-if="item.msgType == '1'"></div>
                        <!-- 图片 -->
                        <el-image v-else-if="item.msgType == '2'"
                                  style="max-width: 150px; max-height: 150px;" fit="contain"
                                  :src="item.content" :preview-src-list="[item.content]">
                        </el-image>
                        <div v-html="item.content" v-else-if="item.msgType == null"></div>
                        <span v-else><i class="el-icon-warning loadingFail"></i>内容加载失败</span>
                      </div>

                    </div>
                    <div class="right" v-if="item.roleType == '2' || item.roleType == '3'">
                      <div class="chatContent">
                        <!-- 普通文字  // 消息类型 1、文字 2、图片 3、语音-->
                        <div v-html="item.content" v-if="item.msgType == '1'"></div>
                        <!-- 图片 -->
                        <el-image v-else-if="item.msgType == '2'"
                                  style="max-width: 150px; max-height: 150px;" fit="contain"
                                  :src="item.content" :preview-src-list="[item.content]">
                        </el-image>
                        <div v-html="item.content" v-else-if="item.msgType == null"></div>
                        <span v-else><i class="el-icon-warning loadingFail"></i>内容加载失败</span>
                      </div>
                      <img v-if="item.headImg" :src="item.headImg" alt="">
                      <img v-if="!item.headImg"
                           src="@/assets/img/logo.jpg"
                           alt="">
                    </div>
                    <div class="center" v-if="item.roleType == '999'"></div>
                  </div>
                </div>
              </div>
              <div v-if="userStatusWarn === true" style="width: 100%;text-align: center;font-size: 11px;font-weight:bold;color:red;background-color: yellow">对方网络异常或已离线</div>
              <div class="lee-chat-send">

                <!-- 快捷操作菜单 -->
                <!-- 固定菜单无滚动 -->
                <div class="lee-chat-sendMenu">
                  <div class="lee-chat-sendMenu-item">
                    <el-upload :action="uploadFileUrl" :show-file-list="false" :limit="1"
                               :on-success="(response, file, fileList) => { handleUploadSuccess(response, file, fileList); }"
                               :on-error="(err, file, fileList) => { handleUploadError(err, file, fileList); }"
                               accept="image/gif,image/jpeg,image/jpg,image/png" :multiple="false"
                               :disabled="!chatingUserId" :file-list="sendImg"
                               :before-upload="(file) => { handleUploadBefore(file); }">
                      <el-button round class="sendMenu-uploadBtn" :disabled="!chatingUserId">
                        <!-- <span class=" iconfont iconimage1"></span> -->
                        使用图片
                      </el-button>
                    </el-upload>
                    <el-button round class="sendMenu-btn" :disabled="!chatingUserId"
                               @click="historyListDialog = true">用户问诊记录
                    </el-button>
                    <el-button round class="sendMenu-btn" :disabled="!chatingUserId"
                               @click="openDrawerForm()">问诊资料
                    </el-button>
                    <!-- <el-button round class="sendMenu-btn">常用语</el-button>-->

                  </div>
                </div>
                <!-- 多菜单滚动 -->
                <!-- <el-carousel class="lee-chat-sendMenu" trigger="click" height="35px" :autoplay="false"
                    indicator-position="none">
                    <el-carousel-item>
                        <div class="lee-chat-sendMenu-item">
                            <el-button round class="sendMenu-btn"
                                @click="openDrawerForm()">查看问诊单</el-button>
                            <el-button round class="sendMenu-btn"
                                @click="historyListDialog = true">用户问诊记录</el-button>
                            <el-upload :action="uploadFileUrl" :show-file-list="false" :limit="1"
                                :on-success="(response, file, fileList) => { handleUploadSuccess(response, file, fileList); }"
                                :on-error="(err, file, fileList) => { handleUploadError(err, file, fileList); }"
                                accept="image/gif,image/jpeg,image/jpg,image/png" :multiple="false"
                                :file-list="sendImg"
                                :before-upload="(file) => { handleUploadBefore(file); }">
                                <div class="lee-chat-send-img-item">
                                    <span class=" iconfont iconimage1"></span>
                                </div>
                            </el-upload>
                        </div>
                    </el-carousel-item>
                </el-carousel> -->
                <!-- 图片、表情 快捷菜单 -->
                <!-- <div class="lee-chat-send-img">
                    <el-upload :action="uploadFileUrl" :show-file-list="false" :limit="1"
                        :on-success="(response, file, fileList) => { handleUploadSuccess(response, file, fileList); }"
                        :on-error="(err, file, fileList) => { handleUploadError(err, file, fileList); }"
                        accept="image/gif,image/jpeg,image/jpg,image/png" :multiple="false"
                        :file-list="sendImg" :before-upload="(file) => { handleUploadBefore(file); }">
                        <div class="lee-chat-send-img-item">
                            <span class=" iconfont iconimage1"></span>
                        </div>
                    </el-upload>
                </div> -->

                <textarea class="lee-chat-send-textarea" @keydown="sendAreaKeydown" v-model="sendVal"
                          :disabled="!chatingUserId"
                          placeholder="请输入聊天内容....按Enter键快速发送,Shift+Enter键换行"></textarea>
                <el-popover placement="top" title="注意" trigger="manual" content="请注意发送内容不能为空"
                            v-model="sendValIsNull">
                  <el-button slot="reference" class="lee-chat-sendBtn" type="primary" plain
                             :disabled="sendBtnDisable === true" @click="sendSocket(sendVal)" ref="sendBtn">发送
                  </el-button>
                </el-popover>
              </div>
            </div>
            <!-- <div class="lee-chat-reserve">
                位置预留区
                <el-button type="primary" @click="drawerForm = true">查看病例</el-button>
            </div> -->
          </div>
        </div>
      </div>
    </div>

    <el-drawer title="问诊资料" :visible.sync="drawerFormStatus" :with-header="false" v-loading="drawerFormLoading">
      <div class="lee-chat-drawer">
        <div class="lee-chat-drawer-title">问诊资料</div>
        <el-form ref="drawerForm" :model="drawerForm" label-width="150px">
          <el-form-item label="姓名" prop="name">
            <el-input v-model="drawerForm.name" placeholder="请输入姓名"></el-input>
          </el-form-item>
          <el-form-item label="年龄" prop="age" :rules="[
                        { type: 'number', message: '年龄必须为数字值' }
                    ]">
            <el-input v-model.number="drawerForm.age" placeholder="请输入年龄"></el-input>
          </el-form-item>
          <el-form-item label="性别" prop="sex">
            <el-select class="lee-chat-drawer-item" v-model="drawerForm.sex" placeholder="请选择用户性别">
              <el-option label="男" value="0"></el-option>
              <el-option label="女" value="1"></el-option>
              <el-option label="未知" value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="联系电话" prop="phone" :rules="[
                        { type: 'number', message: '联系电话必须为数字值' }
                    ]">
            <el-input v-model.number="drawerForm.phone" placeholder="请输入用户联系电话"></el-input>
          </el-form-item>
          <el-form-item label="图片描述">
            <el-image style="width: 100px; height: 100px;margin-right: 10px;"
                      v-for="(itemImg, indexImg) in drawerForm.photoList" :key="indexImg" :src="itemImg.url"
                      :preview-src-list="drawerForm.photoList.map(item => {
                                return item.url
                            })">
            </el-image>
          </el-form-item>

          <el-form-item label="发病部位" prop="disSite">
            <el-input v-model="drawerForm.disSite" placeholder="请输入发病部位"></el-input>
          </el-form-item>
          <el-form-item label="发病时间" prop="disDate">
            <el-date-picker type="date" placeholder="选择发病时间" v-model="drawerForm.disDate"
                            style="width: 100%;"></el-date-picker>
          </el-form-item>
          <el-form-item label="发病症状" prop="disLike">
            <el-input v-model="drawerForm.disLike" placeholder="请输入发病症状"></el-input>
          </el-form-item>
          <el-form-item label="曾经用药治疗史" prop="drugHis">
            <el-input v-model="drawerForm.drugHis" placeholder="请输入曾经用药治疗史"></el-input>
          </el-form-item>
          <el-form-item label="生活工作环境" prop="liveEnv">
            <el-input v-model="drawerForm.liveEnv" placeholder="请输入生活工作环境"></el-input>
          </el-form-item>
          <el-form-item label="是否检查过病因" prop="checkHis">
            <el-select class="lee-chat-drawer-item" v-model="drawerForm.checkHis" placeholder="请选择是否检查过病因">
              <el-option label="否" :value="0"></el-option>
              <el-option label="是" :value="1"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="病情描述" prop="consultationContent">
            <el-input type="textarea" v-model="drawerForm.consultationContent" placeholder="客服填写"></el-input>
          </el-form-item>
          <!-- <el-form-item label="微信备注">
              <el-input type="textarea" disabled v-model="drawerForm.desc"></el-input>
          </el-form-item>
          <el-form-item label="问诊单备注">
              <el-input type="textarea" v-model="drawerForm.desc" placeholder="请输入问诊单备注"></el-input>
          </el-form-item>
          <el-form-item label="活动时间">
              <el-col :span="11">
                  <el-date-picker type="date" placeholder="选择日期" v-model="drawerForm.date1"
                      style="width: 100%;"></el-date-picker>
              </el-col>
              <el-col class="line" :span="2">-</el-col>
              <el-col :span="11">
                  <el-time-picker placeholder="选择时间" v-model="drawerForm.date2"
                      style="width: 100%;"></el-time-picker>
              </el-col>
          </el-form-item>
          <el-form-item label="即时配送">
              <el-switch class="lee-chat-drawer-item" v-model="drawerForm.delivery"></el-switch>
          </el-form-item>
          <el-form-item label="活动性质">
              <el-checkbox-group class="lee-chat-drawer-item" v-model="drawerForm.type">
                  <el-checkbox label="美食/餐厅线上活动" name="type"></el-checkbox>
                  <el-checkbox label="地推活动" name="type"></el-checkbox>
                  <el-checkbox label="线下主题活动" name="type"></el-checkbox>
                  <el-checkbox label="单纯品牌曝光" name="type"></el-checkbox>
              </el-checkbox-group>
          </el-form-item>
          <el-form-item label="特殊资源">
              <el-radio-group class="lee-chat-drawer-item" v-model="drawerForm.resource">
                  <el-radio label="线上品牌商赞助"></el-radio>
                  <el-radio label="线下场地免费"></el-radio>
              </el-radio-group>
          </el-form-item> -->
          <el-form-item>
            <el-button type="primary" @click="saveDrawerForm" :disabled="drawerFormLoading">保存</el-button>
            <el-button @click="closeDrawerForm">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-drawer>

    <el-dialog title="用户问诊记录" :visible.sync="historyListDialog" width="50%">
      <div class="lee-chat-dialog" v-loading="chatLogHistoryListLoging">
        <ul class="lee-infinite-list" v-infinite-scroll="getChatLogList" :infinite-scroll-distance="10"
            :infinite-scroll-delay="200">
          <li v-for="(item, index) in historyList" :key="index" class="lee-infinite-list-item">
            <div class="lee-infinite-list-item-head">
              <div class="lee-infinite-user">
<!--                <img v-if="item.headImg  == conf.msgTyp.TXT" :src="item.headImg" alt="">-->
                <img v-if="!item.headImg"
                     src="@/assets/img/logo.jpg" alt="">
                <span>{{ item.fromUserName }}</span>
              </div>
              <span class="lee-infinite-date">{{ item.createTimeStr }}</span>
            </div>
            <div class="lee-infinite-list-item-content">
              <!-- 普通文字  // 消息类型 1、文字 2、图片 3、语音-->
              <div class="text" v-if="item.msgType == '1'">{{ item.content }}</div>
              <div class="img" v-else-if="item.msgType == '2'">
                <el-image style="width: 100px; height: 100px" :src="item.content"
                          :preview-src-list="[item.content]">
                </el-image>
              </div>
            </div>
          </li>
          <el-empty v-if="historyList.length <= 0" description="暂无问诊记录！"></el-empty>
        </ul>

      </div>
      <span slot="footer" class="dialog-footer">
                <!-- <el-button @click="historyListDialog = false">取 消</el-button>
                <el-button type="primary" @click="historyListDialog = false">确 定</el-button> -->
            </span>
    </el-dialog>

  </div>
</template>
<script>
import {
  getStaffChatList,
  getChatLogList,
  getbConsultationlist,
  updatebConsultation,
  chatLogListOrder,
  wsCheck,
  wsSend,
  msgRead,
  getHistoryMsg,
  getHistoryMsg4WaiterAndUser,
} from "@/api/chat"
import {getUserInfo, removeToken, removeUserInfo} from '@/common/auth';
import {parseTime} from "@/common/ruoyi";

//const WSURI = "ws://127.0.0.1:8080/webSocket";
const WSURI = "wss://wdn.jchcloud.com/webSocket";
const UPLOADURI = process.env.VUE_APP_L_BASE_API + "/system/AppWechat/addfile";
console.log(WSURI, UPLOADURI)

export default {
  name: "chat-view",
  data() {
    return {
      SocketTask: null,
      SocketTaskOnline: false, // socket是否连接状态
      SocketTaskOnlineLoading: false, // socket是否连接状态
      SetHeartbeat: "", // 定时器,心跳包
      enableHeartbeat:"",

      sendVal: "", // 发送的文本消息
      sendImg: [], // 发送的图片消息
      msgReadQueue:[],
      msgReadStatusTask:"",

      fileUploading: false, // 图片上传状态
      sendValIsNull: false, // 发送的文本消息-是否为空
      chatingUserId: null, // 正在聊天的用户id
      // 抽屉表单
      drawerFormStatus: false,
      drawerFormLoading: false,
      drawerForm: {
        id: null, // 当前准备修改的问诊单id
        name: '',
        age: '',
        sex: '',
        phone: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: '',
        consultationContent: "",

        disSite: "", // 发病部位
        disDate: "", // 发病时间
        disLike: "", // 发病症状
        drugHis: "", // 曾经用药治疗史
        liveEnv: "", // 生活工作环境
        checkHis: "", // 是否检查过病因; 0否 1是
      },
      staffId: null, // 客服id
      userInfo: {}, // 客服基本信息
      // 聊天用户列表
      wxUserListLoading: false, // 数据加载状态
      wxUserList: [],
      // 消息缓存，但目前未实现
      msgLocalStore: new Map(),
      // 聊天记录列表
      chatHisListLoging: false,
      chatHisList: [],
      // 历史聊天记录
      historyList: [],
      historyListDialog: false,
      chatLogHistoryListLoging: false,
      historyListPageParams: {
        pageNum: 0,
        pageSize: 10,
        total: -1,
      },

      uploadFileUrl: UPLOADURI,
      // 键盘事件记录(shift+enter)
      keydown_1: -1,
      keydown_2: -1,

      /**
       * 用户状态警告
       */
      userStatusWarn:false,
      sendBtnDisable:true,
    }
  },
  created() {
    // this.initWebSocket();
    this.staffId = getUserInfo().staffId
    console.log(getUserInfo())
    this.userInfo = getUserInfo()
    this.getUserChatList()
  },
  mounted(){

    // 变更消息状态
    if(this.msgReadStatusTask ===""){
      console.log("msgReadStatusTask init  ... ")
      this.msgReadStatusTask = setInterval(()=>{
        if(this.msgReadQueue !=="" && this.msgReadQueue.length >0){
          let logId = this.msgReadQueue.shift();
          console.log("msgReadStatusTask run ... ",logId);
          if(logId!==null && logId!==undefined && logId!=="" && logId!=="null"){
            msgRead({
              msgId:logId
            }).then(res=>{
              if(res.code!==200){
                console.error("message read error : ", logId)
              }
            }).catch(res=>{
              console.error("message read catch 1 : ", logId)
            })
          }
          return
        }
        console.log("msgReadQueue was empty. ");
      },2000)
    }
  },
  beforeDestroy() {
    this.cancelMsgReadStatusTask();
  },
  destroyed() {
    this.closeSocket('页面销毁destroyed自动断开', '3002')
    this.SetHeartbeat = "";
    this.cancelHeartbeat()
  },
  methods: {
    // 业务相关
    cancelMsgReadStatusTask(){
        if(this.msgReadStatusTask !==""){
          clearInterval(this.msgReadStatusTask);
          this.msgReadStatusTask ="";
        }
    },
    afterWSReceiveMsg(data,isInit){
        console.log("afterWSReceiveMsg ",data);
      if(data===null || data===undefined || data ===""
          || data.logId === null || data.logId === undefined || data.logId===""){
        //console.log("data not has logId . ")
        return
      }
      if(!isInit){
        this.msgReadQueue.push(data.logId)
      }
    },
    // 保存问诊单
    saveDrawerForm() {
      let params = {
        staffName: this.userInfo.username,
        id: this.drawerForm.id,
        wechatUsersId: this.chatingUserId,
        consultationName: this.drawerForm.name,
        consultationSex: this.drawerForm.sex,
        consultationAge: this.drawerForm.age,
        consultationPhone: this.drawerForm.phone,
        consultationContent: this.drawerForm.consultationContent,

        disSite: this.drawerForm.disSite, // 发病部位
        disDate: parseTime(this.drawerForm.disDate, "{y}-{m}-{d}"), // 发病时间
        disLike: this.drawerForm.disLike, // 发病症状
        drugHis: this.drawerForm.drugHis, // 曾经用药治疗史
        liveEnv: this.drawerForm.liveEnv, // 生活工作环境
        checkHis: this.drawerForm.checkHis, // 是否检查过病因; 0否 1是
      }

      this.drawerFormLoading = true
      updatebConsultation(params).then(res => {
        this.$message({message: "问诊单信息保存完成！", type: 'success'})
        this.drawerFormLoading = false
        this.closeDrawerForm()
      }).catch(err => {
        this.drawerFormLoading = false
      })
    },
    // 打开问诊单
    openDrawerForm() {

      // 获取问诊单详情drawerForm
      this.drawerFormLoading = true

      setTimeout(() => {
        getbConsultationlist({wechatUsersId: this.chatingUserId}).then(res => {
          this.drawerFormLoading = false
          if (!res.rows || res.rows.length <= 0) {
            return
          }
          let resResult = res.rows[0]
          // 回显赋值
          this.drawerForm = resResult
          this.drawerForm.id = resResult.id
          this.drawerForm.name = resResult.consultationName
          this.drawerForm.age = resResult.consultationAge
          this.drawerForm.sex = resResult.consultationSex
          this.drawerForm.phone = resResult.consultationPhone
          this.drawerForm.photoList = resResult.childen
          this.drawerForm.consultationContent = resResult.consultationContent

          this.drawerForm.disSite = resResult.disSite // 发病部位
          this.drawerForm.disDate = resResult.disDate // 发病时间
          this.drawerForm.disLike = resResult.disLike // 发病症状
          this.drawerForm.drugHis = resResult.drugHis // 曾经用药治疗史
          this.drawerForm.liveEnv = resResult.liveEnv // 生活工作环境
          this.drawerForm.checkHis = resResult.checkHis // 是否检查过病因; 0否 1是
          this.drawerForm = {...this.drawerForm}

          this.drawerFormStatus = true
        }).catch(err => {
          this.drawerFormStatus = false
          this.drawerFormLoading = false
        })
      }, 500)
    },
    // 关闭问诊单
    closeDrawerForm() {
      this.drawerForm = {
        name: '',
        age: '',
        sex: '',
        phone: '',
      }
      this.drawerFormStatus = false
    },
    // 暂离
    leaveWork() {
      this.closeSocket('暂离', '3003')
      this.cancelHeartbeat()
      this.chatingUserId = null
    },
    // 获取用户问诊线上历史记录
    getChatLogList() {
      this.historyListPageParams.pageNum++
      let params = {
        fromUserId: this.chatingUserId,
        pageNum: this.historyListPageParams.pageNum,
        pageSize: this.historyListPageParams.pageSize
      }
      this.chatLogHistoryListLoging = true

      // 校验当前数据是否溢出
      if (this.historyListPageParams.total != -1) {
        let allPageNum = Math.ceil(this.historyListPageParams.total / params.pageSize)
        if (this.historyListPageParams.pageNum > allPageNum) {
          setTimeout(() => {
            this.historyListPageParams.pageNum--
            this.$message({message: "当前已经是最后一条数据咯！", type: 'warning'})
            this.chatLogHistoryListLoging = false
          }, 2000)
          return
        }
      }
      // 提取线上历史记录（时间倒序，由大到小，获取所有数据）
      chatLogListOrder(params).then(res => {
        this.historyListPageParams.total = res.total
        this.chatLogHistoryListLoging = false
        this.historyList = this.historyList.concat(res.rows)
        this.historyList = [...this.historyList]
      }).catch(err => {
        console.error(err)
        this.historyListPageParams.pageNum--
        this.chatLogHistoryListLoging = false
      })
    },
    // 获取用户本地或线上聊天历史记录列表
    getChatHisList(paramsWeId) {
      // 数据格式参考
      //   // 用户聊天记录列表
      //   chatChildList:[{
      //     username:"", // 客服/用户
      //     avatal:"", // 头像
      //     content:"",
      //     msgType: 1, //  消息类型 1、文字 2、图片 3、语音 4、卡片
      //   }]

      // -- 前端不持久用户聊天数据，否则客户端内存会溢出
      this.chatHisListLoging = true
      let wid = this.staffId
      getHistoryMsg4WaiterAndUser({
        wid: wid,
        uid: paramsWeId
      }).then(res => {
        let data =res.data
        if(data!==null && data!==undefined && data.length >0){
          for(let i in data){
            this.isNewChatUser(data[i],true);
          }
        }
        this.chatHisListLoging = false
      }).catch(err => {
        console.error(err)
        this.chatHisListLoging = false
      })

      // for (let i in this.wxUserList) {
      //   if (this.wxUserList[i].weId == paramsWeId) {
      //     if (this.wxUserList[i].chatChildList) {
      //       this.chatHisListLoging = false
      //     }else{
      //       getHistoryMsg({
      //         uid: paramsWeId
      //       }).then(res => {
      //         let data =res.data
      //         if(data!==null && data!==undefined && data.length >0){
      //           for(let i in data){
      //             this.isNewChatUser(data[i],true);
      //           }
      //         }
      //         this.chatHisListLoging = false
      //       }).catch(err => {
      //         console.error(err)
      //         this.chatHisListLoging = false
      //       })
      //     }
      //   }
      // }
      // 查找本地记录
      // for (let i in this.wxUserList) {
      //   if (this.wxUserList[i].weId == paramsWeId) {
      //     if (this.wxUserList[i].chatChildList && this.wxUserList[i].chatChildList.length > 0) {
      //       // 先提取本地聊天记录
      //       this.chatHisList = []
      //       this.chatHisList = [...this.wxUserList[i].chatChildList]
      //       setTimeout(() => {
      //         this.chatHisListLoging = false
      //         this.scrollBottom()
      //       }, 2000)
      //     } else {
      //       // 无本地聊天记录则提取历史记录（时间倒序，由小到大，获取十条数据）
      //       getHistoryMsg({
      //         uid: paramsWeId
      //       }).then(res => {
      //         let data =res.data
      //         if(data!==null && data!==undefined && data.length >0){
      //           for(let i in data){
      //             this.isNewChatUser(data[i],true);
      //           }
      //         }
      //         this.chatHisListLoging = false
      //       }).catch(err => {
      //         console.error(err)
      //         this.chatHisListLoging = false
      //       })
      //     }
      //     break;
      //   }
      // }
    },
    // 文本域键盘事件处理
    // shift+enter 换行
    // enter 发送消息
    sendAreaKeydown(e) {
      // 记录Shift事件
      if (e.keyCode == 16) {
        this.keydown_1 = 16
      }
      // 记录回车事件
      if (e.keyCode == 13) {
        this.keydown_2 = 13
      }

      // 组合键shift+enter 换行处理
      if (this.keydown_1 === 16 && e.keyCode === 13) {
        this.keydown_1 = -1
        this.keydown_2 = -1
        return
      }
      // 发送消息处理
      if (e.keyCode == 13 && this.keydown_1 != 16) {
        // 阻止enter事件默认换行
        e.preventDefault() // 阻止浏览器默认的换行操作
        this.sendSocket(this.sendVal) // 发送消息
        return
      }
      // 按键其他非组合按键情况
      if (![13, 16].includes(e.keyCode)) {
        this.keydown_1 = -1
      }
    },

    // 退出登录
    exitChat() {
      this.closeSocket('页面销毁destroyed自动断开', '3002')
      this.cancelHeartbeat()
      removeToken()
      removeUserInfo()
      this.$router.push({path: "/login"})
    },
    //清空聊天记录
    clearUserChats(){
      for (let i in this.wxUserList) {
        this.wxUserList[i].chatChildList = [];
      }
    },
    // 获取聊天用户列表
    getUserChatList() {
      this.wxUserListLoading = true
      getStaffChatList({staffId: this.staffId}).then(res => {
        this.wxUserList = res.staffChats
        for(let i in this.wxUserList) {
          let data_ = this.wxUserList[i]
          this.msgLocalStore.set(""+data_.weId, [])
        }
        this.wxUserListLoading = false
        console.log("getUserChatList : ",this.msgLocalStore)
        // 数据格式参考
      }).catch(err => {
        this.wxUserListLoading = false
      })
    },
    // 是否新聊天用户
    isNewChatUser(receiveData,isInit) {
      //TODO: 消息去重
      let msgType = receiveData.msgType;
      // 心跳消息
      if(msgType === "90"){
        console.log("ping mgs ... ")
        return;
      }
      console.log("是否新聊天用户", this.wxUserList)
      let roleType = receiveData.roleType
      let weId_ = receiveData.to;
      if(roleType === "1"){
        weId_ = receiveData.from;
      }

      // 初始化
      if (this.wxUserList === undefined || this.wxUserList === null || this.wxUserList ===""){
        this.wxUserList = []
      }

      let isNewMsg = true
      let weUserName = receiveData.userName;
      let headImg = receiveData.headImg;

      //本地未缓存用户
      let userMsgsList = this.msgLocalStore.get(weId_);
      if(userMsgsList === undefined || userMsgsList === null || userMsgsList ===""){
        userMsgsList = []
        this.msgLocalStore.set(weId_,userMsgsList)

        // 更新用户列表 追加至最前方
        this.wxUserList.unshift({
          isNewMsg: isNewMsg, // 对当前用户的新聊天标识进行处理
          weId: weId_,
          weUserName: weUserName,
          headImg: headImg
        })
      }

      // 不缓存消息
      // userMsgsList.push({
      //   msgType: receiveData.msgType, // 消息类型 1、文字 2、图片 3、语音 4、卡片
      //   roleType: roleType ? roleType : 1, // 角色类型 1、用户 2、客服 3、智能文本 999、动作提示
      //   content: receiveData.message,
      //   headImg: headImg,
      //   username: weUserName,
      //   weUserName : weUserName
      // })

      // 判断是否为当前正在聊天的用户
      if (weId_ === this.chatingUserId || parseInt(weId_) === this.chatingUserId) {
        console.log("当前消息为正在聊天消息", receiveData.from, this.chatingUserId)
        // 将消息插入聊天面板数组
        this.insertChatHisList({
          msgType: receiveData.msgType, // 消息类型 1、文字 2、图片 3、语音 4、卡片
          roleType: receiveData.roleType ? receiveData.roleType : '1', // 角色类型 1、用户 2、客服 3、智能文本 999、动作提示
          message: receiveData.message,
          headImg: receiveData.headImg,
          username: receiveData.userNamecd
        })
        //设置非离线状态
        this.userStatusWarn = false
      }else{
        let newIndex=-1;
        let newUser = "";
        this.wxUserList.forEach((item, index) => {
          if (weId_ === item.weId || parseInt(weId_) === item.weId) {
            console.log(this.chatingUserId + "-- isnew")
            item.isNewMsg = true
            newIndex = index
            newUser = item
          }
        })
        if(newIndex!==null && newIndex!==undefined && newIndex !=="" && newIndex >=0
          && newUser!==null && newUser!==undefined && newUser !==""){
          this.wxUserList.splice(newIndex,1)
          this.wxUserList.unshift(newUser)
        }
      }

      let msgStatus = receiveData.msgStatus
      if(msgStatus === "0"){
        this.afterWSReceiveMsg(receiveData);
      }
      // 更新用户列表数据
      this.wxUserList = [...this.wxUserList]
    },
    // 选择聊天用户
    selectChatUser(paramsWeId,idx) {
      if(this.chatingUserId === paramsWeId){
        return
      }
      console.log("准备与微信用户-" + paramsWeId + "-聊天")
      this.chatingUserId = paramsWeId

      let userMsgsList = this.msgLocalStore.get(paramsWeId);
      if(userMsgsList){
        userMsgsList = []
      }
      this.wxUserList.forEach((item, index) => {
        if (paramsWeId === item.weId) {
          item.isNewMsg = false
        }
      })
      // 清空聊表面板
      this.chatHisList = []
      this.getChatHisList(paramsWeId)
      this.historyListPageParams = {
        pageNum: 0,
        pageSize: 10,
        total: -1,
      }
      this.historyList = []
      this.getChatLogList()
      this.sendBtnDisable = false
      this.userStatusWarn = false
    },
    // 滑动到聊天区域最下方
    scrollBottom() {
      setTimeout(() => {
        this.$nextTick(() => {
          let scrollBottom = this.$el.querySelector('.lee-chat-list-ul')
          if (!scrollBottom || !scrollBottom.scrollHeight) {
            return
          }
          scrollBottom.scrollTop = scrollBottom.scrollHeight
        })
      }, 100)
    },
    // 将消息插入聊天面板数组
    insertChatHisList(chatData) {
      // 聊天数据插入聊天数组
      this.chatHisList.push({
        msgType: chatData.msgType, // 消息类型 1、文字 2、图片 3、语音 4、卡片
        roleType: chatData.roleType ? chatData.roleType : '1', // 角色类型 1、用户 2、客服 3、智能文本 999、动作提示
        content: chatData.message.replace(/\n/g, "<br />"),
        headImg: chatData.headImg,
        weUserName: chatData.userName,
      })
      // 更新聊天记录
      this.chatHisList = [...this.chatHisList]
      this.scrollBottom()
    },

    // 连接相关
    // 初始化连接
    initWebSocket() {
      this.SocketTaskOnlineLoading = true
      //初始化weosocket
      this.SocketTask = new WebSocket(WSURI + "/" + this.staffId + "/2");
      // 客户端接收服务端数据时触发
      this.SocketTask.onmessage = (onMsg) => {
        console.log("onmessage", onMsg)
        this.receiveSocket(onMsg)
      };
      // 连接建立时触发
      this.SocketTask.onopen = (onOpen) => {
        console.log("onOpen", onOpen)
        this.SocketTaskOnline = true // 连接
        this.SocketTaskOnlineLoading = false
        // 清空本地聊天记录
        this.clearUserChats()
        // 开始保持心跳
        this.sendHeartbeat()
      };
      // 通信发生错误时触发
      this.SocketTask.onerror = (onError) => {
        this.SocketTaskOnline = false // 断开
        this.SocketTaskOnlineLoading = false
        console.log("onError", onError)
        this.closeSocket('通信发生错误时触发', '3002')
      };
      // 连接关闭时触发
      this.SocketTask.onclose = (onClose) => {
        this.SocketTaskOnline = false // 断开
        this.SocketTaskOnlineLoading = false
        console.log("onClose", onClose)
        this.closeSocket('连接关闭时触发', '3002')
      };
    },
    // 接收socket数据
    receiveSocket(msgData) {
      // 解析socket接收数据
      let receiveData = JSON.parse(msgData.data)
      console.log("接收到来自服务端的消息", receiveData)
      // 处理状态码
      switch (receiveData.code) {
          // 分配用户
        case "2":
          this.chatingUserId = receiveData.from
          break;
        case "200":
          this.isNewChatUser(receiveData)
          break;
        default:
          this.isNewChatUser(receiveData)
      }
    },

    // 文件上传前
    handleUploadBefore(file) {
      this.fileUploading = true // 文件是否正在上传
    },
    // 文件上传失败
    handleUploadError(err, file, fileList) {
      this.fileUploading = false // 文件是否正在上传
      this.$modal.msgError("文件上传失败");
      console.error("文件上传失败了呦！👉", err, file, fileList)
    },
    // 附件上传完成   *** 发送图片
    handleUploadSuccess(res, file, fileList) {
      try {
        this.fileUploading = false // 文件是否正在上传
        if (res.code == 200) {
          let result = res
          let imgUrl = process.env.VUE_APP_L_BASE_API + result.msg
          this.sendImg = []
          this.sendImg.push({
            name: file.name,
            uid: file.uid,
            url: imgUrl
          })

          console.log("发送图片", file, fileList, this.sendImg)
          // 消息类型 1、文字 2、图片 3、语音 4、卡片
          this.sendSocket(this.sendImg[0].url, 2) // 发送消息

        } else {
          this.fileUploading = false // 文件是否正在上传
          this.sendImg.pop()
          this.$message({
            message: '图片发送失败',
            type: 'error'
          });
          console.error(res)
        }
      } catch (err) {
        this.fileUploading = false // 文件是否正在上传
        this.sendImg.pop()
        this.$message({
          message: '图片发送异常',
          type: 'error'
        });
        console.error(err)
      }
    },
    // 发送消息
    sendSocket(val, msgType) {
      // 客服是否在线
      if (!this.SocketTaskOnline) {
        this.$message({
          message: '请点击工作按钮进入工作状态！',
          type: 'warning'
        });
        return
      }
      // 当前是否存在正在聊天的微信用户
      if (!this.chatingUserId) {
        this.$message({
          message: '尚未选中会话',
          type: 'warning'
        });
        return
      }
      // 发送的内容是否为空
      if (!val || val.length <= 0) {
        setTimeout(() => {
          this.sendValIsNull = false
        }, 1500)
        this.sendValIsNull = true
        return
      }
      let this_ = this;
      let from = this.staffId;
      let to = this.chatingUserId;
      if (from === "") {
        this.$message({
          message: '客服状态异常，请重新登录。',
          type: 'error'
        });
        return;
      }
      if (to === "") {
        this.$message({
          message: '客户状态异常，信息无法发送。',
          type: 'error'
        });
        return;
      }
      // 禁用发送按钮
      this.sendBtnDisable =true
      wsSend({
        "to": this_.chatingUserId, // 发送的用户唯一标识
        "from": this_.staffId, // 客服唯一标识
        "text": val.replace(/\n/g, "<br />"), // 具体消息
        "msgType": msgType ? msgType : "1", // 消息类型 1、文字 2、图片 3、语音
        "roleType": "2", // 角色类型 1、用户 2、客服 3、智能文本
        "type": "2", // socket消息类型
      }).then(res => {
        let code = res.code
        let data = res.data
        if (code === 200) {
          // 消息发送完成后清理数据
          this_.sendVal = ""
          this_.sendImg = []
          // 将消息插入聊天面板数组
          this_.insertChatHisList({
            msgType: msgType ? msgType : '1', // 消息类型 1、文字 2、图片 3、语音 4、卡片
            roleType: '2', // 角色类型 1、用户 2、客服 3、智能文本 999、动作提示
            message: val,
            headImg: this_.userInfo.headImg,
            weUserName: this_.userInfo.userName,
          })
          this.userStatusWarn = false
          // // 组织发送数据参数
          // let wsParams = JSON.stringify({
          //   "to": this_.chatingUserId, // 发送的用户唯一标识
          //   "from": this_.staffId, // 客服唯一标识
          //   "text": val.replace(/\n/g, "<br />"), // 具体消息
          //   "msgType": msgType ? msgType : "1", // 消息类型 1、文字 2、图片 3、语音
          //   "roleType": "2", // 角色类型 1、用户 2、客服 3、智能文本
          //   "type": "2", // socket消息类型
          // })
          // console.log("正在发送ws消息：" + wsParams)
          // this_.sendVal = ""
          // this_.sendImg = []
          // this_.SocketTask.send(wsParams) // 发送ws消息
          return;
        }
        if (code !== 200) {
          // 发起者断连
          if (data === "20" || data === "21") {
            this.$message({
              message: '会话未知原因中止。。',
              type: 'error'
            });
            this.closeSocket("请求失败或网络异常。", 1000)
            return;
          }
          if (data === "40" || data === "41") {
            // 消息发送完成后清理数据
            this_.sendVal = ""
            this_.sendImg = []
            // 将消息插入聊天面板数组
            this_.insertChatHisList({
              msgType: msgType ? msgType : '1', // 消息类型 1、文字 2、图片 3、语音 4、卡片
              roleType: '2', // 角色类型 1、用户 2、客服 3、智能文本 999、动作提示
              message: val,
              headImg: this_.userInfo.headImg,
              weUserName: this_.userInfo.userName,
            })
            this.userStatusWarn = true
          }
          if (data === "60") {
            this.$message({
              message: '对方不在线或网络异常',
              type: 'error'
            });
            return;
          }
          //console.log("wscheck error : ", res)
        }
      }).catch(res => {
        console.log("wsCheck error :", res);
        // this.$message({
        //   message: '请求失败或网络异常。',
        //   type: 'error'
        // });
        //this.closeSocket("请求失败或网络异常。", 1000)
      }).finally(()=>{
        setTimeout(()=>{
          this.sendBtnDisable = false
        },700)
      })
    },
    closeSocket(reson, code) {
      if (!this.SocketTask) {
        return
      }
      console.log("closeSocket", reson, code)
      this.SocketTask.close(); //离开路由之后断开websocket连接
      this.cancelHeartbeat()
    },
    // 定时发送心跳
    sendHeartbeat() {
      if(this.SetHeartbeat===""){
        this.SetHeartbeat = setInterval(() => {
          console.log("heartbeat ... ")
          let sendVal = JSON.stringify({
            "to": "", // 发送的用户唯一标识
            "from": this.staffId, // 客服唯一标识
            "text": "", // 具体消息
            "type": "2", // 消息类型
            "msgType": "90"
          })
          this.SocketTask.send(sendVal)
        }, 30 * 1000)
        return
      }
      this.SetHeartbeat = "";
    },
    cancelHeartbeat(){
      if(this.SetHeartbeat!==""){
        clearInterval(this.SetHeartbeat);
        this.SetHeartbeat = "";
      }
    }
  }
}
</script>
<style scoped lang="scss">
.lee-chat {
  background-color: #2E2F3D;
  height: 100vh;
  width: 100vw;
  color: #145694;
}

.lee-chat-main {
  width: 60vw;
  height: 80vh;
  background-color: #fff;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: flex;
}

.lee-chat-left {
  width: 300px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.lee-chat-userInfo {
  height: 130px;
  width: 100%;
  background-image: linear-gradient(45deg, #62B4D1, #C9DFEA);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px 20px;
  box-sizing: border-box;
}

.lee-chat-info {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  color: #333;
}

.lee-chat-info img {
  width: 60px;
  height: 60px;
  border-radius: 3px;
  margin-right: 20px;
}

.lee-chat-userInfoBtn {
  width: 100%;
  text-align: right;
}

.lee-chat-userList {
  width: 100%;
  height: calc(100% - 130px);
  border-right: 1px solid #eee;
  overflow-x: hidden;
  overflow-y: auto;
  box-sizing: border-box;
}

.lee-chat-userList .activeUser {
  background-color: #D2E6F9 !important;
}

.lee-chat-userList-item {
  width: 100%;
  height: 40px;
  background-color: #EDF3FA;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: #333;
  font-size: 13px;
  position: relative;
  cursor: pointer;

}

.lee-chat-userList-item:hover {
  background-color: #D2E6F9 !important;
}

.lee-chat-userList-item img {
  width: 30px;
  height: 30px;
  border-radius: 3px;
  margin: 0 20px;
}

.lee-chat-userList .active {
  background-color: #D2E6F9 !important;
}

.lee-chat-userList-item .userList-news {
  height: 12px;
  width: 24px;
  background-color: #ff1a1a;
  color: #fff;
  font-size: 8px;
  font-weight: bold;
  position: absolute;
  right: 5px;
  top: 10px;
  border-radius: 3px;
  line-height: 12px;
  text-align: center;
}

.lee-chat-right {
  width: 100%;
  height: 100%;
}

.lee-chat-top {
  height: 70px;
  width: 100%;
  border-bottom: 1px solid #dfdfdf;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.lee-chat-company {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: #333;
  font-size: 16px;
}

.lee-chat-company img {
  height: 40px;
  width: 40px;
  border-radius: 3px;
  margin-right: 20px;
  font-size: 25px;
  font-weight: bold;
  color: #333;
}

.lee-chat-top-menuBtn {
  width: 100%;
  text-align: right;
}

.lee-chat-center {
  width: 100%;
  height: calc(100% - 70px);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.lee-chat-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
}

.lee-chat-list {
  height: calc(100% - 200px);
  width: 100%;
  padding-left: 20px;
  box-sizing: border-box;
  // padding-bottom: 40px;

  .lee-chat-list-ul {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;


    .lee-chat-list-li:first-child {
      margin-top: 20px;
    }

    .lee-chat-list-li {
      width: 100%;
      position: relative;
      margin-bottom: 20px;

      img {
        height: 32px;
        width: 32px;
        border-radius: 2px;
        margin: 0 20px;
      }

      .chatContent {
        background-image: linear-gradient(45deg, #83d1e2, #C8E7EC);
        border-radius: 5px;
        color: #333;
        padding: 10px 10px;
        box-sizing: border-box;
        max-width: 70%;
        position: relative;

        img {
          min-height: 100px;
          min-width: 100px;
          border-radius: 5px;
        }

        .loadingFail {
          font-size: 25px;
          color: #EE6C1C;
          position: absolute;
        }
      }

      .left {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;

        .loadingFail {
          right: -30px;
        }
      }

      .right {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-end;

        .loadingFail {
          left: -30px;
        }
      }

      .center {
        width: 100%;
        height: 30px;
        text-align: center;
        background-color: #c7d1d4;
      }
    }
  }
}


.lee-chat-send {
  height: 200px;
  width: 100%;
  background-color: #ffffff;
  border-top: 1px solid #eee;
  position: relative;

  .lee-chat-sendMenu {
    height: 35px;
    width: 100%;
    overflow: hidden;
    // position: absolute;
    // top: -35px;

    ::v-deep .el-carousel__arrow {
      height: 25px !important;
      width: 25px !important;
    }

    ::v-deep .el-carousel__arrow--left {
      left: 5px !important;
    }

    ::v-deep .el-carousel__arrow--right,
    .el-notification.right {
      right: 5px !important;
    }

    :hover {
      .lee-chat-sendMenu-item {
        padding: 5px 40px;
      }
    }

    .lee-chat-sendMenu-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: nowrap;
      width: 100%;
      height: 100%;
      padding: 5px 10px;
      box-sizing: border-box;
      transition: all .3s ease;

      .sendMenu-btn {
        height: 25px;
        width: 80px;
        font-size: 12px;
        color: #a3a3a3;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 0;
      }

      .sendMenu-uploadBtn {
        height: 25px;
        width: 80px;
        font-size: 12px;
        color: #a3a3a3;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 0;
        margin: 0 10px;
      }

    }
  }
}

.lee-chat-send-img {
  height: 35px;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  .lee-chat-send-img-item {
    padding: 10px;
    margin-right: 10px;

    .iconfont {
      font-size: 25px;
      color: #9c9c9c;
    }
  }
}

.lee-chat-send-textarea {
  height: calc(100% - 30px - 10px - 35px);
  width: 100%;
  border: none !important;
  padding: 5px 20px;
  background-color: #fff;
  color: #333;
  box-sizing: border-box;
  font-size: 16px;
  resize: none;
  outline: none;
}

.lee-chat-send-textarea::placeholder {
  color: #c4c4c4;
}

.lee-chat-send-textarea:focus {
  /* background-color: #fafafa; */
}

.lee-chat-sendBtn {
  height: 30px;
  width: 60px;
  padding: 0 12px;
  float: right;
  margin: 0;
  margin-right: 10px;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
  user-select: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
}

.lee-chat-reserve {
  width: 500px;
  height: 100%;
  background-color: #6e6e58;
}

// 抽屉/弹出框
.lee-chat-drawer {
  padding: 30px;

  .lee-chat-drawer-title {
    margin-bottom: 30px;
    font-size: 20px;
    font-weight: bold;
    color: #333;
  }

  .lee-chat-drawer-item {
    width: 100%;
    text-align: left;
  }
}

.lee-chat-dialog {
  background-color: #fff;

  .lee-infinite-list {
    overflow-y: auto;
    overflow-x: hidden;
    height: 50vh;
    list-style: none;

    .lee-infinite-list-item {
      min-height: 100px;
      width: 100%;
      border-bottom: 1px solid #eee;

      .lee-infinite-list-item-head {
        font-size: 15px;
        font-weight: bold;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        .lee-infinite-user {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          padding: 10px 0;

          img {
            height: 50px;
            width: 50px;
            margin-right: 20px;
            border-radius: 5px;
          }
        }

        .lee-infinite-date {
          padding: 10px 0;
        }
      }

      .lee-infinite-list-item-content {
        min-height: 50px;
        width: 70%;
        padding: 10px 0;
        box-sizing: border-box;

        .text {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          justify-content: flex-start;
        }

        .img {
          text-align: left;

          img {
            height: 80px;
            width: 80px;
            border-radius: 5px;
          }
        }
      }
    }
  }
}
</style>
import axios from 'axios'
import { Notification, MessageBox, Message, Loading } from 'element-ui'
import { getToken } from '@/common/auth'
import errorCode from '@/common/errorCode'
import { tansParams, blobValidate } from "@/common/ruoyi";
import cache from '@/plugins/cache'

let downloadLoadingInstance;
// 是否显示重新登录
export let isRelogin = { show: false };

let resWhitelist = ["/wsapi/sendMsg"]

// 创建axios实例
const request = axios.create({
    // axios中请求配置有baseURL选项，表示请求URL公共部分
    baseURL: process.env.VUE_APP_L_BASE_API,
    // 超时
    timeout: 10000
})
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8;'
/**
 * 选择使用的服务
 * @param {*} serviceName 服务名称："L" 在线客服系统后台服务  "H" 唯达宁后台管理系统服务
 * 使用方式
 * import { setService, request } from '@/common/request'
 * setService("L")// 设置使用在线客服服务接口
 * return request({
    url: '/customerLogin',
    headers: {},
    method: 'post',
    data: data
  })
 */
function setService(serviceName) {
    request.defaults.baseURL = serviceName == "L" ? process.env.VUE_APP_L_BASE_API : process.env.VUE_APP_H_BASE_API;
    console.warn("正在使用的接口服务api：", request.defaults.baseURL)
}

// request拦截器
request.interceptors.request.use(config => {
    // 是否需要设置超时时间限制 true 需要 false 不需要
    config.timeout = !config.isTimeout ? undefined : config.timeout;
    // 是否需要设置 token
    const isToken = (config.headers || {}).isToken === false
    // 是否需要防止数据重复提交
    const isRepeatSubmit = (config.headers || {}).repeatSubmit === false
    if (getToken() && !isToken) {
        config.headers['Authentication'] = getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    // get请求映射params参数
    if (config.method === 'get' && config.params) {
        let url = config.url + '?' + tansParams(config.params);
        url = url.slice(0, -1);
        config.params = {};
        config.url = url;
    }
    if (!isRepeatSubmit && (config.method === 'post' || config.method === 'put')) {
        const requestObj = {
            url: config.url,
            data: typeof config.data === 'object' ? JSON.stringify(config.data) : config.data,
            time: new Date().getTime()
        }
        const requestSize = Object.keys(JSON.stringify(requestObj)).length; // 请求数据大小
        const limitSize = 5 * 1024 * 1024; // 限制存放数据5M
        if (requestSize >= limitSize) {
            console.warn(`[${config.url}]: ` + '请求数据大小超出允许的5M限制，无法进行防重复提交验证。')
            return config;
        }
        const sessionObj = cache.session.getJSON('sessionObj')
        if (sessionObj === undefined || sessionObj === null || sessionObj === '') {
            cache.session.setJSON('sessionObj', requestObj)
        } else {
            const s_url = sessionObj.url;                  // 请求地址
            const s_data = sessionObj.data;                // 请求数据
            const s_time = sessionObj.time;                // 请求时间
            const interval = 700;                         // 间隔时间(ms)，小于此时间视为重复提交
            if (s_data === requestObj.data && requestObj.time - s_time < interval && s_url === requestObj.url) {
                const message = '数据正在处理，请勿重复提交';
                console.warn(`[${s_url}]: ` + message)
                return Promise.reject(new Error(message))
            } else {
                cache.session.setJSON('sessionObj', requestObj)
            }
        }
    }
    return config
}, error => {
    console.log(error)
    Promise.reject(error)
})

// 响应拦截器
request.interceptors.response.use(res => {
    if(res.config.skipInterceptors) {
        console.log("skipInterceptors response skip.")
        return res.data
    }
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200;
    // 获取错误信息
    const msg = errorCode[code] || res.data.msg || errorCode['default']
    // 二进制数据则直接返回
    if (res.request.responseType === 'blob' || res.request.responseType === 'arraybuffer') {
        return res.data
    }
    if (code !== 200 && code !== "200") {
        Notification.error({ title: msg })
        return Promise.reject('接口响应数据结构错误，校对code值')
    } else {
        return res.data
    }
},
    error => {
        console.log('err' + error)
        let { message } = error;
        if (message == "Network Error") {
            message = "后端接口连接异常";
        } else if (message.includes("timeout")) {
            message = "系统接口请求超时";
        } else if (message.includes("Request failed with status code")) {
            message = "系统接口" + message.substr(message.length - 3) + "异常";
        }
        Message({ message: message, type: 'error', duration: 5 * 1000 })
        return Promise.reject(error)
    }
)

// 通用下载方法
export function download(url, params, filename, config) {
    downloadLoadingInstance = Loading.service({ text: "正在下载数据，请稍候", spinner: "el-icon-loading", background: "rgba(0, 0, 0, 0.7)", })
    return request.post(url, params, {
        transformRequest: [(params) => { return tansParams(params) }],
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        responseType: 'blob',
        ...config
    }).then(async (data) => {
        const isBlob = blobValidate(data);
        if (isBlob) {
            const blob = new Blob([data])
        } else {
            const resText = await data.text();
            const rspObj = JSON.parse(resText);
            const errMsg = errorCode[rspObj.code] || rspObj.msg || errorCode['default']
            Message.error(errMsg);
        }
        downloadLoadingInstance.close();
    }).catch((r) => {
        console.error(r)
        Message.error('下载文件出现错误，请联系管理员！')
        downloadLoadingInstance.close();
    })
}

export {
    request,
    setService
}

import Vue from 'vue'
import VueRouter from 'vue-router'
import chat from '../views/chat.vue'
import { getToken } from "@/common/auth"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'chat',
    component: chat
  },
  // {
  //   path: '/chat',
  //   name: 'chat',
  //   component: () => import('../views/chat.vue')
  // },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/login.vue')
  }
]
// 创建路由实例
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
// 路由守卫
router.beforeEach((to, from, next) => {
  // next()
  if (
    // 检查用户是否已登录
    !getToken() &&
    // ❗️ 避免无限重定向
    to.name !== 'login'
  ) {
    // 将用户重定向到登录页面
    console.error("用户未登录")
    next({ path: '/login' })
  } else {
    next()
  }
})

export default router

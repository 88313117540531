import Cookies from 'js-cookie'

const TokenKey = 'Authentication'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

const UserInfo = 'userInfo'

export function getUserInfo() {
  if(!Cookies.get(UserInfo)){return ""}
  return JSON.parse(Cookies.get(UserInfo))
}

export function setUserInfo(user) {
  return Cookies.set(UserInfo, JSON.stringify(user))
}

export function removeUserInfo() {
  return Cookies.remove(UserInfo)
}

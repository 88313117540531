import { request } from '@/common/request'

// 获取聊天用户列表
// staffId=客服id
export function getStaffChatList(params) {
    return request({
        url: '/chatlog/staffChat',
        method: 'get',
        params: params
    })
}
// 获取用户的最近聊天记录
// chatlog/chatLogList?userId=11
export function getChatLogList(params) {
    return request({
        url: '/chatlog/chatLogList',
        method: 'get',
        params: params
    })
}
// 获取用户的历史聊天记录
// chatlog/chatLogList?userId=11
export function chatLogListOrder(params) {
    return request({
        url: '/chatlog/chatLogListOrder',
        method: 'get',
        params: params
    })
}
// 获取用户的问诊单
// wechatUsersId	是	string	用户id 不传查询全部
export function getbConsultationlist(params) {
    return request({
        url: '/system/AppWechat/getbConsultationlist',
        method: 'post',
        data: params
    })
}
// 客服修改问诊单
// id	是	String	当前业务id
// wechatUsersId	是	String	用户id
// consultationName	是	String	名称
// consultationSex	是	String	性别（0：男 1：女 2：未知）
// consultationAge	是	String	年龄
// consultationPhone	是	String	电话
// 图片	是	集合	图片集合
export function updatebConsultation(params) {
    return request({
        url: '/system/AppWechat/updatebConsultation',
        method: 'post',
        data: params
    })
}

export function wsCheck(params) {
    return request({
        url: "/wsapi/wscheck",
        method: 'post',
        data: params
    })
}

export function wsSend(params) {
    return request({
        url: "/wsapi/sendMsg",
        method: 'post',
        data: params,
        skipInterceptors:true
    })
}

export function msgRead(params) {
    return request({
        url: "/wsapi/msgRead",
        method: 'post',
        data: params,
        skipInterceptors:true
    })
}
export function getHistoryMsg(params) {
    return request({
        url: "/wsapi/getHistoryMsg",
        method: 'post',
        data: params
    })
}

export function getHistoryMsg4WaiterAndUser(params) {
    return request({
        url: "/wsapi/getHistoryMsg4WaiterAndUser",
        method: 'post',
        data: params
    })
}